import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Token } from "../types/token";

@Injectable({ providedIn: "root" })
export class LoginService {
  constructor(private http: HttpClient) {}

  getPassword(username) {
    const phone = username.replace("+", "");

    const postData = {
      phone: `46${phone}`,
    };

    // TODO: [LO-179] change to an better forgot password sulution, this is not safe!
    return this.http
      .post("/mobile/v3/newpassword", postData, {
        // headers: new HttpHeaders({
        //   Accept: "application/json",
        //   "Content-Type": "application/x-www-form-urlencoded",
        // }),
      })
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.handleError<Token>("newpassword"))
      );
  }

  isUsername(username): any {
    const phone = username.replace("+", "");

    var postData = {
      phone: phone,
    };

    return this.http.post("webb/v3/isusername", postData).pipe(
      map((result) => {
        return result;
      }),
      catchError(this.handleError<Token>("newpassword"))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      //this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
