import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { RegistrationService } from "./registration.service";
import { CaptchaComponent } from "angular-captcha";
import { FormBuilder, Validators } from "@angular/forms";
import { Registration } from "./registration";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { UniqueUserValidator } from "./uniqueUserValidator";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "your-form-with-captcha",
  templateUrl: "registration.component.html",
  styleUrls: ["registration.component.css"],
  providers: [RegistrationService],
})
export class RegistrationComponent implements OnInit {
  public enebelCaptcha = environment.production && !environment.staging;

  @ViewChild(CaptchaComponent, { static: true })
  captchaComponent: CaptchaComponent;
  @ViewChild("phone", { static: true }) phone: ElementRef;
  newUserForm = this.fb.group({
    firstName: ["", Validators.required],
    lastName: ["", Validators.required],
    phone: [
      "+46",
      [
        Validators.pattern("^([+]46)\\s*(7[02369])\\s*(\\d{4})\\s*(\\d{3})$"),
        Validators.required,
        Validators.minLength(12),
        Validators.maxLength(12),
      ],
      this.uniqueUserValidator.validate.bind(this.uniqueUserValidator),
    ],
    company: ["", Validators.required],
    email: ["", [Validators.required, Validators.email]],
    captcha: ["", this.enebelCaptcha ? Validators.required : null],
    agreed: [false, Validators.requiredTrue],
  });
  registration: Registration;

  constructor(
    private registrationService: RegistrationService,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private router: Router,
    private uniqueUserValidator: UniqueUserValidator,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {}

  onSubmit(): void {
    if (this.newUserForm.invalid) {
      return;
    }

    // post the captcha data to the /your-app-backend-path on your backend
    if (this.enebelCaptcha) {
      // get the user-entered captcha code value to be validated at the backend side
      let userEnteredCaptchaCode = this.captchaComponent.userEnteredCaptchaCode;

      // get the id of a captcha instance that the user tried to solve
      let captchaId = this.captchaComponent.captchaId;

      const postData = {
        userEnteredCaptchaCode: userEnteredCaptchaCode,
        captchaId: captchaId,
      };

      this.registrationService.send(postData).subscribe(
        (response) => {
          if (response.success == false) {
            // captcha validation failed; reload image
            this.captchaComponent.reloadImage();
            // TODO: maybe display an error message, too
            this.newUserForm.controls["captcha"].setErrors({ incorrect: true });
          } else {
            this.register();
          }
        },
        (error) => {
          throw new Error(error);
        }
      );
    } else {
      this.register();
    }
  }

  register() {
    this.registration = new Registration(
      this.newUserForm.get("firstName").value.trim(),
      this.newUserForm.get("lastName").value.trim(),
      this.newUserForm.get("email").value.trim(),
      this.newUserForm.get("phone").value.trim().replace("+", ""),
      this.newUserForm.get("company").value.trim(),
      "user"
    );

    const optionsPromises = [
      this.translate.get("registration.successMessage").toPromise(),
      this.translate.get("registration.errorMessage").toPromise(),
    ];

    Promise.all(optionsPromises).then((translations) => {
      this.registrationService.addUser(this.registration).subscribe(
        (registration) => {
          this.openSnackBar(translations[0], true);
        },
        (error) => {
          this.openSnackBar(translations[1], false);
        }
      );
    });
  }

  onPhoneChange(args) {
    setTimeout(() => {
      if (this.phone.nativeElement.value.length == 1) {
        this.phone.nativeElement.value = "+";
      } else if (this.phone.nativeElement.value.length == 2) {
        this.phone.nativeElement.value = "+4";
      } else if (this.phone.nativeElement.value.length == 3) {
        this.phone.nativeElement.value = "+46";
      } else if (this.phone.nativeElement.value.length > 12) {
        this.phone.nativeElement.value = this.phone.nativeElement.value.slice(
          0,
          -1
        );
      } else if (this.phone.nativeElement.value.length != 0) {
        this.phone.nativeElement.value =
          "+46" + this.phone.nativeElement.value.substring(3);
      }
    }, 250);
  }

  openSnackBar(message: string, redirect: boolean) {
    let snackBarRef = this._snackBar.open(message, "", {
      duration: 3000,
    });

    if (redirect) {
      snackBarRef.afterDismissed().subscribe(() => {
        this.goToStart();
      });
    }
  }

  goToStart() {
    this.router.navigate(["/start"]);
  }
}
