<lo-navbar></lo-navbar>

<div class="lo-form-page">
  <mat-card id="newUser">
    <form autocomplete="off" [formGroup]="newUserForm" (ngSubmit)="onSubmit()">
      <mat-card-content>
        <h1>Ny användare</h1>

        <mat-form-field>
          <input
            matInput
            [placeholder]="'common.firstName' | translate"
            type="text"
            formControlName="firstName"
            required
          />
          <mat-error *ngIf="newUserForm.get('firstName').errors">
            {{ "common.firstNameHintMissing" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            [placeholder]="'common.lastName' | translate"
            type="text"
            formControlName="lastName"
            required
          />
          <mat-error *ngIf="newUserForm.get('lastName').errors">
            {{ "common.lastNameHintMissing" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            [placeholder]="'common.mobileNumber' | translate"
            #phone
            formControlName="phone"
            (ngModelChange)="onPhoneChange($event)"
            required
          />
          <mat-error
            *ngIf="
              newUserForm.get('phone').errors &&
              !newUserForm.get('phone')?.errors?.phoneTaken
            "
          >
            {{ "common.mobileNumberHintMissing" | translate }}
          </mat-error>
          <mat-error *ngIf="newUserForm.get('phone')?.errors?.phoneTaken">
            {{ "common.firstNameHintMissing" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            [placeholder]="'common.email' | translate"
            formControlName="email"
            required
          />
          <mat-error *ngIf="newUserForm.get('email').errors">
            {{ "common.emailHintMissing" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            [placeholder]="'common.company' | translate"
            formControlName="company"
            required
          />
          <mat-error *ngIf="newUserForm.get('company').errors">
            {{ "common.companyHintMissing" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            formControlName="agreed"
            value=""
            class="hiddenInput"
            required
          />
          <mat-error>
            {{ "registration.termsOfUseHintRequired" | translate }}
          </mat-error>
          <mat-checkbox
            formControlName="agreed"
            class="dialogItem"
            color="primary"
            matInput
            required
            >{{ "registration.termsOfUseFundHere" | translate }}
            <a
              href="https://lambertsson.com/om-lambertsson/hantering-av-personuppgifter/"
              target="_blank"
            >
              {{ "registration.termsOfUseLink" | translate }}
            </a>
          </mat-checkbox>
        </mat-form-field>
        <div class="captcha">
          <botdetect-captcha
            captchaStyleName="ExampleCaptcha"
          ></botdetect-captcha>
        </div>
        <mat-form-field class="fullWidth">
          <input
            matInput
            id="CaptchaCode"
            [placeholder]="'common.email' | translate"
            formControlName="captcha"
          />
          <mat-error *ngIf="newUserForm.get('captcha').errors">
            {{ "registration.captchaHintError" | translate }}
          </mat-error>
        </mat-form-field>

        <lo-lang-select></lo-lang-select>
      </mat-card-content>
      <mat-card-actions>
        <button
          fxFlex="125px"
          mat-raised-button
          color="primary"
          type="submit"
          [disabled]="!newUserForm.valid"
        >
          {{ "registration.submitButton" | translate }}
        </button>
      </mat-card-actions>
    </form>
  </mat-card>
</div>
