import { NgModule } from "@angular/core";
import { BrowserModule, Title } from "@angular/platform-browser";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { AdminAuthGuard } from "./auth/adminAuth.guard";
import { AuthGuard } from "./auth/auth.guard";
import { AuthService } from "./auth/auth.service";
import { AuthInterceptor } from "./auth/auth.interceptor";
import { AppRoutingModule } from "./app-routing.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MatListModule } from "@angular/material/list";
import { LoginComponent } from "./login/login.component";
import { FormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatInputModule } from "@angular/material/input";
import { RegistrationComponent } from "./registration/registration.component";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatMenuModule } from "@angular/material/menu";
import { StartComponent } from "./widgets/start/start.component";
import { MatDialogModule } from "@angular/material/dialog";
import { BotDetectCaptchaModule } from "angular-captcha";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MatTreeModule } from "@angular/material/tree";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatCardModule } from "@angular/material/card";
import { MatBadgeModule } from "@angular/material/badge";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatDividerModule } from "@angular/material/divider";
import { MyAccountComponent } from "./myaccount/myaccount.component";
import { PasswordValidatorDirective } from "./myaccount/password-validator.directive";
import { Confirm } from "./myaccount/myaccount.component";
import { UniqueUserValidatorDirective } from "./registration/uniqueUserValidator";
import { AngularFireAnalyticsModule } from "@angular/fire/analytics";
import { AngularFireModule } from "@angular/fire";
import { ChangePasswordDialogComponent } from "./myaccount/dialog/dialog.component";

import { LoComponentsModule } from "./lo-components/lo-components.module";
import { environment } from "src/environments/environment";
import { MatPaginatorConfig } from "src/utils/MatPaginatorConfig";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
// import { ServiceWorkerModule } from '@angular/service-worker';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
const firebaseConfig = {
  apiKey: "AIzaSyDIrwHGZAIYh9DevUzTVkpKQ2pErEGwLtk",
  authDomain: "lambertsson-online-68eaa.firebaseapp.com",
  databaseURL: "https://lambertsson-online-68eaa.firebaseio.com",
  projectId: "lambertsson-online-68eaa",
  storageBucket: "lambertsson-online-68eaa.appspot.com",
  messagingSenderId: "607544212605",
  appId: "1:607544212605:web:9c95cb1a27e44ddd9b8514",
  measurementId: "G-40MS04PTSD",
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegistrationComponent,
    StartComponent,
    MyAccountComponent,
    PasswordValidatorDirective,
    Confirm,
    UniqueUserValidatorDirective,
    ChangePasswordDialogComponent,
  ],
  entryComponents: [LoginComponent, Confirm],
  imports: [
    BrowserModule,
    MatInputModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FontAwesomeModule,
    MatListModule,
    MatSidenavModule,
    MatMenuModule,
    MatDialogModule,
    MatTreeModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    MatCardModule,
    MatBadgeModule,
    FlexLayoutModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatDividerModule,
    LoComponentsModule,
    TranslateModule.forRoot({ 
      // The TranslateModule.forRoot is moved from the shared component (lo-components.module.ts) to up here
      // Reason: Importing the shared component will create more instances of the singleton translateModule
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BotDetectCaptchaModule.forRoot({
      captchaEndpoint: `${environment.loApiUrl}/simple-captcha-handler`,
    }),
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAnalyticsModule,
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    AdminAuthGuard,
    AuthGuard,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    Title,
    // TODO: need to find a way to set this from chosen lang in LangService
    { provide: MAT_DATE_LOCALE, useValue: "sv-SE" },
    { provide: MatPaginatorIntl, useClass: MatPaginatorConfig }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
