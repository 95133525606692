// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { version } from "../../package.json";

export const environment = {
  appVersion: `v${version}`,
  production: true,
  staging: true,
  loApiUrl: "https://next-api.lambertsson-online.se",
  oAuthClientSecret: "uq39llTB6ICph321s0ENT1PwsbEBlqlJFVojZLei",
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
